B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'WelcomeController', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->
  
  $scope.init = () ->
    $scope.sending_email = false
    $scope.thanks_email = false
    $scope.principal_menu_is_open = false
    $scope.current_product_souple = 1
    $scope.current_product_rigide = 1
    $scope.current_product_portrait = 1
    $scope.current_product_paysage = 1
    $scope.current_product_yearbook = 1
    $scope.current_background = 1
    $scope.screen_width = window.innerWidth
    #$scope.modal = new bootstrap.Modal(document.getElementById('login_modal'), {backdrop:'static', keyboard:false})
    $(".retour").hide()
    $scope.lastScrollTop = 0
    #if ($("#top-page"))
    #  window.addEventListener("load", $scope.toggleBacktotop);
    #  document.addEventListener("scroll", $scope.toggleBacktotop);
    setInterval $scope.change_backgroud_auto, 15000
    
    $timeout( () ->
      if $scope.screen_width > 480
        $(".background").height($scope.screen_width / 2.666)
        $(".top").height($scope.screen_width / 2.666 + 100)
      else
        $(".background").height($scope.screen_width / 1.333)
        $(".top").height($scope.screen_width / 1.333 + 100)
    , 100)
    
    $timeout( () ->
      $('.crisp-client .cc-1m7s[data-full-view=true] .cc-iv0q .cc-dc5e').attr('style', 'bottom:84px !important;display:block !important');
      $('.crisp-client .cc-52lo[data-full-view=true] .cc-kegp .cc-1oun').attr('style', 'bottom:84px !important;display:block !important');
    , 10000)

  
  $scope.change_backgroud_auto = () ->
    console.log("okiiiii")
    if $scope.current_background == 1
      $scope.current_background = 2
    else
      $scope.current_background = 1
    $scope.apply_scope()
  
  $scope.change_backgroud = (x) ->
    $scope.current_background = x
    
  $scope.open_signup_modal_modal = () ->
    $scope.modal_register.hide()
    $scope.modal_signup = new bootstrap.Modal(document.getElementById('signup_modal'), {backdrop:'static', keyboard:false})
    $scope.modal_signup.show()
  
  
  $scope.toggleBacktotop = () ->
    multiple = window.scrollY % 10
    #on check tout les 10 pixels
    if multiple == 0
      if window.scrollY > $("#hero").height() 
        $("#principal_menu").hide()
        $("#top-page").hide()
        $(".retour").show()
      else
        $(".retour").hide()
      if window.scrollY < $scope.lastScrollTop
        $("#principal_menu").hide()
        $("#top-page").show()
      $scope.lastScrollTop = window.scrollY
    
  $scope.open_principal_menu = () ->
    $scope.principal_menu_is_open = true
    $("#principal_menu").show()
    #$("#top-page").hide()
    #$('.left-items').attr('style', 'display:block !important;width:350px');
    #$('.retour1').attr('style', 'top: 27%;');



  $scope.close_principal_menu = () ->
    $scope.principal_menu_is_open = false
    $("#principal_menu").hide()
    #$("#principal_menu").hide()
    #$("#top-page").show()
    #$('.left-items').attr('style', 'display:block !important;width:350px');
    #$('.retour1').attr('style', 'top: 21%;');
    #if ($(window).width() < 500)
    #  $('.left-items').attr('style', 'display:none !important;width:350px');



  $scope.open_rappel_app = () ->
    $scope.open_rappel_app = new bootstrap.Modal(document.getElementById('rappel_app_albums'), {})
    $scope.open_rappel_app.show()  


  $scope.close_bandeau = () ->
    $('.bandeau_app_mobile').attr('style', 'display:none !important');
    $('.social_links').attr('style', 'transform: translateY(28px);');
    if ($(window).width() < 500)
      $('.crisp-client .cc-52lo[data-full-view=true] .cc-kegp .cc-1oun').attr('style', 'bottom:84px !important;display:block !important');
     
         
      

   $scope.masquer_bandeau = () ->
    if ($(window).width() < 500)
      $('.bandeau_livraison_offerte').attr('style', 'display:none !important');
      $('.social_pictos').attr('style', 'transform: translate3d(40px, -17px, 0px);');
      $('.btn-close-liv-hide').show()
      $('.social_links_after').attr('style','transform: translate3d(4px, 54px, 10px);');
      $('.crisp-client .cc-52lo[data-full-view=true] .cc-kegp .cc-1oun').attr('style', 'bottom:57px !important');
    else  if ($(window).width() > 500)
      $('.bandeau_livraison_offerte').attr('style', 'display:none !important');
      $('.social_pictos').attr('style', 'transform: translate3d(-6px, 134px, 10px);');
      $('.btn-close-liv-hide').show()
      $('.social_links_after').attr('style','transform: translate3d(4px, -3px, 10px'); 
      $('.crisp-client .cc-52lo .cc-kegp .cc-1oun').attr('style','bottom: 12px!important;');
      $('.btn-close-liv').attr('style','transform: translate3d(103px, -33px, 10px);'); 
      
      
     

  $scope.afficher_bandeau = () ->
    if ($(window).width() < 500)
      $('.bandeau_livraison_offerte').attr('style', 'display:block !important;');
      $('.btn-close-liv-hide').hide()
      $('.social_links_after').attr('style','transform: translate3d(4px, -16px, 10px);');
      $('.btn-close-liv').attr('style', 'float: right; transform: translate3d(27px, -38px, 10px);');
      $('.crisp-client .cc-52lo[data-full-view=true] .cc-kegp .cc-1oun').attr('style', 'bottom:87px !important');
    else if($(window).width() > 500)
      $('.bandeau_livraison_offerte').attr('style', 'display:block !important;');
      $('.btn-close-liv-hide').hide()
      $('.social_links_after').attr('style','transform: translate3d(4px, -71px, 10px);');
      $('.btn-close-liv').attr('style', 'float: right;transform: translate3d(103px, -33px, 10px);');
    
  
  $scope.afficher_menu_hidden = () ->
    $('.retour1').attr('style', 'transform: translate3d(0px, 32px, 10px);');
        
   
 
   
    
    
  

  $scope.set_template_first = (type) ->
    window.location = "/create_book?open="+type

  $scope.set_product_first = (type) ->
    if type == 'mag'
      window.location = "/create_book?open=1"
    else if type == 'album'
      window.location = "/create_book?open=2"
    else if type == 'paysage'
      window.location = "/create_book?open=3"
    else if type == 'portrait'
      window.location = "/create_book?open=4"
  
  $scope.set_gift = (type) ->
    window.location = "/create_gift"
  
  $scope.set_product_tirages = (type) ->

    if type == 'retro'
      window.location = "/tirages_photos?tirage=retro"
    else if type == 'classique'
      window.location = "/tirages_photos?tirage=classique"
  

  $scope.close_register = () ->
    $('.modal-content-register').attr('style', 'display:none !important');
    
    $('.modal-backdrop.show').attr('style', 'opacity: 0;');

  $scope.open_register_page = () ->
    $scope.modal_register.hide()
    $('#sigup_box').toggle();

  $scope.afficherModal = () ->
    $scope.default_params()
    $scope.current_template = 'product'
    $scope.current_panel = 'product'
    
    $scope.hide_theme = true
    $scope.modal_create_yearbook = new bootstrap.Modal(document.getElementById('create_yearbookv2'), {})
    $scope.modal_create_yearbook.show()
    $scope.current_panel = "product"
    $scope.set_modal_dimenssions()

  $scope.set_modal_dimenssions = () ->
    $timeout( () ->
      $scope.modal_height = $("#create_yearbookv2 .modal-dialog").height()
      $scope.header_height = 92
      item_theme = (($scope.modal_height - 130) / 3)
      $(".items-theme").height(40)
      $(".items-theme").css("padding-top", (item_theme - 60) + "px")
    , 500)

  $scope.default_params = () ->
    $scope.current_size = "big"
    $scope.current_sub_panel = "format"
    $scope.nb_page = 24
    $scope.current_format = "portrait"

  $scope.openModalSaveMail = () ->
    $scope.modal_newsletter = new bootstrap.Modal(document.getElementById('save_mail_modal'), {})
    $scope.modal_newsletter.show()
  
  $scope.openModalRegister = () ->
    $scope.modal_register = new bootstrap.Modal(document.getElementById('register_modal'), {})
    $scope.modal_register.show()
    $('#sigup_box').hide();
  
  $scope.openModalEnSavoir = () ->
    $scope.modal_en_savoir = new bootstrap.Modal(document.getElementById('en_savoir_modal'), {})
    $scope.modal_en_savoir.show()
    
  $scope.closeModal_en_savoir = () ->
    $('.modal-en-savoir').modal('hide')
  
    
  $scope.open_login_modal = () ->
    $scope.modal_register = new bootstrap.Modal(document.getElementById('login_modal'), {backdrop: 'static', keyboard: false})
    $scope.modal_register.show()
    $('.crisp-client .cc-52lo[data-full-view=true] .cc-kegp .cc-1oun').attr('style', 'display:none !important');
    
  $scope.open_others_address = () ->
    $scope.open_others_address = new bootstrap.Modal(document.getElementById('autres_adresses'), {})
    $scope.modal_app.show()
  
  $scope.openModalModif = () ->
    $scope.modal_modif = new bootstrap.Modal(document.getElementById('modif_modal'), {})
    $scope.modal_modif.show()
    $('#modal').hide();

  $scope.send_email = () ->
    if $scope.email != "" && $scope.email != undefined
      $scope.sending_email = true
      $.post("/save_email_newsletter", {email: $scope.email})
        .then (response) ->
          $scope.sending_email = false
          $scope.thanks_email = true
          $scope.apply_scope()
          
  $scope.closeModal_register = () ->
    $('.modal-register').modal('hide')
  
  
 
   $scope.gotTo = (product) ->
      $scope.current_product_souple = product
      
  $scope.gotToRigide = (product) ->
    $scope.current_product_rigide = product
    
  $scope.gotToPortrait = (product) ->
    $scope.current_product_portrait = product
  
  $scope.gotToYearbook = (product) ->
    $scope.current_product_yearbook = product
    
  $scope.gotToPaysage = (product) ->
    $scope.current_product_paysage = product

  $scope.close_modal = () ->
    $scope.modal_newsletter.hide()
    $scope.sending_email = false
    $scope.thanks_email = false
    $scope.email = ""
    
  $scope.previous_product_carousel_home = (product) ->
    if product == "souple"
      if $scope.current_product_souple == 3
        $scope.current_product_souple = 1
      else
        $scope.current_product_souple = $scope.current_product_souple + 1
    else if product == "rigide"
      if $scope.current_product_rigide == 3
        $scope.current_product_rigide = 1
      else
        $scope.current_product_rigide = $scope.current_product_rigide + 1
    else if product == "portrait"
      if $scope.current_product_portrait == 3
        $scope.current_product_portrait = 1
      else
        $scope.current_product_portrait = $scope.current_product_portrait + 1
    else if product == "paysage"
      if $scope.current_product_paysage == 3
        $scope.current_product_paysage = 1
      else
        $scope.current_product_paysage = $scope.current_product_paysage + 1
    else if product == "yearbook"
      if $scope.current_product_yearbook == 3
        $scope.current_product_yearbook = 1
      else
        $scope.current_product_yearbook = $scope.current_product_yearbook + 1
      
  $scope.next_product_carousel_home = (product) ->
    if product == "souple"
      if $scope.current_product_souple == 1
        $scope.current_product_souple = 3
      else
        $scope.current_product_souple = $scope.current_product_souple - 1
    else if product == "rigide"
      if $scope.current_product_rigide == 1
        $scope.current_product_rigide = 3
      else
        $scope.current_product_rigide = $scope.current_product_rigide - 1
    else if product == "portrait"
      if $scope.current_product_portrait == 1
        $scope.current_product_portrait = 3
      else
        $scope.current_product_portrait = $scope.current_product_portrait - 1
    else if product == "paysage"
      if $scope.current_product_paysage == 1
        $scope.current_product_paysage = 3
      else
        $scope.current_product_paysage = $scope.current_product_paysage - 1
    else if product == "yearbook"
      if $scope.current_product_yearbook == 1
        $scope.current_product_yearbook = 3
      else
        $scope.current_product_yearbook = $scope.current_product_yearbook - 1
    $scope.apply_scope()
    
  $scope.apply_scope = () ->
    try
      if !$scope.$$phase
        $scope.$apply()
    catch e
]



  