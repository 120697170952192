B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'YearbookController', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->
  $scope.init_gift_card = (current_user_email) ->
    $scope.modal_create_gift = new bootstrap.Modal(document.getElementById('create_yearbookv2'), {})
    $scope.modal_create_gift.show()
    $scope.card_price = 25
    $scope.card_occasion = 1
    $scope.card = {to: "", for: "", message: "", send_to: ""}
    
    $timeout( () ->
      tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      tooltipList = tooltipTriggerList.map((tooltipTriggerEl) ->
        new (bootstrap.Tooltip)(tooltipTriggerEl)
      )
      $scope.card_width = $("#gift_card_img").width()
      $scope.card_height = $("#gift_card_img").height()
      $scope.loading_form =  false
    , 500)

  


    
  $scope.init = (open) ->
    console.log()
    #SIZE
    $scope.products_height = (window.innerHeight - 105) / 3
    $scope.product_height = (window.innerHeight - 290) / 2#$scope.products_height - 60
    $scope.product_height = (window.innerWidth - 200) / 4#$scope.products_height - 60
    $scope.mokup_height = (window.innerHeight - 380)
    $scope.margin_top_step_1 = (window.innerHeight - $scope.product_height - 260) / 2
    
    $scope.default_params()
    $scope.current_panel = "product"
    $scope.nombre_page =  24
    $scope.current_product = ""
    $scope.current_template = "perso"#1
    $scope.templates =["summer", "mariage", "voyage", "anniversaire", "famille", "occasion"]

    $scope.social_network_id = ""
    $scope.price = 0
    $scope.hide_theme = false
    $scope.hide_offre = false
    $scope.current_product_souple1 = 1
    $scope.current_product_rigide1 = 1
    $scope.album_name = "Mon album"
    $scope.changing_name = false
    if ($(window).width() > 500)
      $("#img-logo").hide()
    
    $scope.product_selected = "carre_13"
    if open != false
      if open == '1'
        $scope.current_category_product = "pix"
        $scope.set_product_first('mag')
      else if open == '2'
        $scope.set_product_first('album')
        $scope.current_category_product = "carre"
        $scope.current_size = "small"
        $scope.product_selected = "carre_21"
      else if open == '3'
        $scope.set_product_first('album')
        $scope.current_category_product = "paysage"
        $scope.current_size = "big"
        $scope.product_selected = "a4_paysage"
      else if open == '4'
        $scope.current_category_product = "portrait"
        $scope.set_product_first('album')
        $scope.current_size = "big"
        $scope.product_selected = "a4"
      else
        $scope.current_category_product = "pix"
        $scope.current_category = open
        $scope.set_template_first(open)
        $scope.set_theme(open)
        $scope.set_theme(open)
        if open == "none"
          $scope.current_template = null
  
  $scope.change_current_category_product = (cat) ->
    $scope.current_category_product = cat
    if cat == "pix"
      $scope.current_size = "small"
      $scope.product_selected = "carre_13"
    else if cat == "carre"
      $scope.current_size = "small"
      $scope.product_selected = "carre_21"
    else if cat == "portrait"
      $scope.current_size = "big"
      $scope.product_selected = "a4"
    else if cat == "paysage"
      $scope.current_size = "big"
      $scope.product_selected = "a4_paysage"
  $scope.select_product = (product) ->
    $scope.product_selected = product
  
  $scope.open_join_group = () ->
    $scope.modal_app = new bootstrap.Modal(document.getElementById("join_invite"), {})
    $scope.modal_app.show()
    
  $scope.go_to_editeurV2_no_login = (type_connexion) ->
    $scope.load_yearbook = true
    $.post("/start_no_login?product="+$scope.product_selected+"&format_size="+$scope.current_size+ "&format_type="+$scope.current_format+ "&theme=" + $scope.current_template + "&social_network_id=" + $scope.social_network_id + "&nb_page="+$scope.nb_page + "&theme=" + $scope.current_template+ "&name=" + $scope.album_name + "&method_type=" + $scope.method_type)
      .then (response) ->
        window.location = "/users/"+type_connexion
    
    
  $scope.go_to_editeurV2 = () ->
    $scope.load_yearbook = true
    $.post("/startV2?product="+$scope.product_selected+"&format_size="+$scope.current_size+ "&format_type="+$scope.current_format+ "&theme=" + $scope.current_template + "&social_network_id=" + $scope.social_network_id + "&nb_page="+$scope.nb_page + "&theme=" + $scope.current_template+ "&name=" + $scope.album_name + "&method_type=" + $scope.method_type)
      .then (response) ->
        window.location = "/social_networks/"+response.social_network_id+"/books"
        
  $scope.change_card_price = (price) ->
    $scope.card_price = price
    $("#card_price").val((price))
  
  $scope.change_card_occasion = (occas) ->
    $scope.card_occasion = occas
    $("#card_event_type").val((occas))
    
  $scope.paid_card = () ->
    $scope.card_price = price
  
  
  
  $scope.default_params = () ->
    $scope.current_size = "mini"
    $scope.big =false
    $scope.small =false
    $scope.current_sub_panel = "size"
    $scope.nb_page = 24
    $scope.current_format = "carree"
    
  $scope.open_signup_modal_modal = () ->
    $scope.modal_register.hide()
    $scope.modal_signup = new bootstrap.Modal(document.getElementById('signup_modal'), {})
    $scope.modal_signup.show()
    
  $scope.next_sub_panel = (sub_panel) ->
    $scope.current_sub_panel = sub_panel

  $scope.set_modal_dimenssions = () ->
    $timeout( () ->
      $scope.modal_height = $("#create_yearbookv2 .modal-dialog").height()
      $scope.header_height = 92
      #$scope.modal_footer_height = $("#create_yearbookv2 .modal-footer").height() + 38
    
      #alert($scope.modal_height)
      item_theme = (($scope.modal_height - 130) / 3)
      $(".items-theme").height(40)
      $(".items-theme").css("padding-top", (item_theme - 60) + "px")
    , 500)
    
   $scope.change_album_name = () ->
     $scope.changing_name = true
     $timeout( () ->
       $('#change_name_album').focus()
     , 100)
     
   $scope.preview_step = () ->
    if $scope.current_panel == "format" && $scope.current_sub_panel == 'page'
      $scope.current_sub_panel = "size"
    else if $scope.current_panel == "format" && $scope.current_sub_panel == 'size'
      $scope.current_sub_panel = "format"
    else if ($scope.current_panel == "final" && $scope.hide_theme == false)
      $scope.current_panel = "template"
      #$("#4").removeClass('active-modal')
    else if ($scope.current_panel == "final" && $scope.hide_theme == true)
      $scope.current_panel = "format"
      #$("#4").removeClass('active-modal')
    else if $scope.current_panel == "template"
      $scope.current_panel = "format"
      #$("#3").removeClass('active-modal')
    else if ($scope.current_panel == "format")
      $scope.current_panel = "product"
      #$("#2").removeClass('active-modal')
    else if $scope.hide_offre == false
      $scope.current_panel = "product"
      #$("#1").removeClass('active-modal')

  $scope.next_step = () ->
    console.log($scope.current_panel)
    if $scope.current_panel == "product"
      console.log($scope.current_panel);
      $scope.current_panel = "format"
      #$(".close").css("left","93%");
    else if ($scope.current_panel == "format")
      #$(".close").css("left","45%");
      console.log($scope.current_panel); 
      $scope.current_panel = "template"
      #$("#3").addClass('active-modal')
    else
      #$(".close").css("left","93%");
      $scope.current_panel = "final"
      #$("#4").addClass('active-modal')
      console.log($scope.current_panel);
    #if ($scope.current_panel =="product")
    #  $(".slide-left-modal").hide()
    #else
    #  #$(".slide-left-modal").show()

  $scope.select_size = (size) ->
    console.log(size)
    $scope.current_size = size
    $scope.set_price()

  $scope.select_format = (format) ->
    console.log(format)
    $scope.current_format = format
    if format != "carree" && $scope.current_size == "mini"
      $scope.current_size = "small"
      #$scope.apply_scope()
    $scope.set_price()
  
  $scope.set_theme = (theme) ->
    $timeout( () ->
      console.log("$scope.set_theme")
      $( ".items-theme" ).removeClass( "theme_opacity" )
      console.log($scope.current_template)
      console.log(theme)
      if $scope.current_template == theme
        $scope.current_template = null
      else
        $scope.current_template = theme
        angular.forEach $scope.templates, (value, index) ->
          console.log(value + "==" + $scope.current_template)
          if value != $scope.current_template
            $( ".theme" + value ).addClass( "theme_opacity" )
    , 100)

  $scope.open_set_yearbook = (social_network_id) ->
    $scope.social_network_id = social_network_id
    $scope.modal_create_yearbook = new bootstrap.Modal(document.getElementById('create_yearbookv2'), {})
    $scope.modal_create_yearbook.show()
    $scope.current_panel = "product"
    $scope.set_modal_dimenssions()
  $scope.set_product_tirages = (type) ->
    console.log("je ssssss")
    if type == 'retro'
      window.location = "/tirages_photos?tirage=retro"
    else if type == 'classique'
      window.location = "/tirages_photos?tirage=classique"

  $scope.set_template_first = (theme) ->
    $scope.default_params()
    $scope.current_template = theme
    $scope.hide_theme = true
    #$(".close").css("left","93%");
    ##$(".slide-left-modal").hide()
    #$("#3").hide()
    #$('.1').show()
    #$('.3').hide()
    #$('#1').show()
    #$('#1').html(1)
    #$('#2').html(2)
    #$('#4').html(3)

    $scope.modal_create_yearbook = new bootstrap.Modal(document.getElementById('create_yearbookv2'), {})
    $scope.modal_create_yearbook.show()
    $scope.current_panel = "product"
    $scope.set_modal_dimenssions()
  $scope.set_product = (product) ->
    #$(".slide-left-modal").show()
    $scope.current_product = product
    $scope.current_panel = "product"
    $scope.set_price()
    $scope.next_step()
    #$scope.apply_scope()
    #$scope.next_step()
    #$(".close").css("left","93%");
  
  $scope.set_product_first = (product) ->
    $( ".items-theme" ).removeClass( "theme_item_selected theme_opacity" )
    $scope.default_params()
    $scope.hide_offre = true;
    $scope.modal_create_yearbook = new bootstrap.Modal(document.getElementById('create_yearbookv2'), {})
    $scope.modal_create_yearbook.show()
    $scope.current_product = product
    $scope.current_panel = "product"
    $scope.set_price()
    $scope.next_step()
    $scope.apply_scope()
    $scope.set_modal_dimenssions()
    #$(".close").css("left","45%");

  
  $scope.go_to_invite = () ->
    $scope.load_yearbook = true
    $.post("/start?product="+$scope.current_product+"&format_size="+$scope.current_size+ "&format_type="+$scope.current_format+ "&theme=" + $scope.current_template + "&social_network_id=" + $scope.social_network_id + "&nb_page="+$scope.nb_page + "&product=" + $scope.current_product + "&theme=" + $scope.current_template + "&method_type=" + $scope.method_type)
      .then (response) ->
        window.location = "/social_networks/" + response.social_network_id + "?open_invite=true"


  
  $scope.go_to_editeur_with_social_network = () ->
    $scope.load_yearbook = true
    $.post("/start?product="+$scope.current_product+"&format_size="+$scope.current_size+ "&format_type="+$scope.current_format+ "&theme=" + $scope.current_template + "&social_network_id=" + $scope.social_network_id + "&nb_page="+$scope.nb_page + "&product=" + $scope.current_product + "&theme=" + $scope.current_template+ "&name=" + $scope.album_name + "&social_network_id=" + $scope.current_social_network_id + "&method_type=" + $scope.method_type)
      .then (response) ->
        window.location = "/social_networks/"+$scope.current_social_network_id+"/yearbooks"
        
  $scope.go_to_editeur = () ->
    $scope.load_yearbook = true
    $.post("/start?product="+$scope.current_product+"&format_size="+$scope.current_size+ "&format_type="+$scope.current_format+ "&theme=" + $scope.current_template + "&social_network_id=" + $scope.social_network_id + "&nb_page="+$scope.nb_page + "&product=" + $scope.current_product + "&theme=" + $scope.current_template+ "&name=" + $scope.album_name + "&method_type=" + $scope.method_type)
      .then (response) ->
        window.location = "/social_networks/"+response.social_network_id+"/books"
  
  $scope.goto_template = () ->
    $scope.current_panel = "template"
    #if $scope.current_category != undefined
    #  $scope.set_theme($scope.current_category)
    #$("#3").addClass('active-modal')
    #$(".close").css("left","45%");

  $scope.goto_final = () ->
    #$(".close").css("left","93%");
    $scope.current_panel = "final"
    #$("#4").addClass('active-modal')
  $scope.goto_format = () ->
    $scope.current_panel = "format"
    $scope.set_price()
    #$(".close").css("left","45%");
    #$("#2").addClass('active-modal')
    
  $scope.set_price = () ->
    if $scope.nb_page > 23 && $scope.nb_page < 101
      if $scope.current_product == "mag"
        if $scope.current_size == 'big' && $scope.current_format == 'portrait'
          price = 34.99
        else if $scope.current_size == 'small' && $scope.current_format == 'portrait'
          price = 24.99
        else if $scope.current_size == 'big' && $scope.current_format == 'paysage'
          price = 39.99
        else if $scope.current_size == 'small' && $scope.current_format == 'paysage'
          price = 24.99
        else if $scope.current_size == 'big' && $scope.current_format == 'carree'
          price = 49.00
        else if $scope.current_size == 'small' && $scope.current_format == 'carree'
          price = 27.99
        else if $scope.current_size == 'mini' && $scope.current_format == 'carree'
          price = 14.99
      else
        if $scope.current_size == 'big' && $scope.current_format == 'portrait'
          price = 39.99
        else if $scope.current_size == 'small' && $scope.current_format == 'portrait'
          price = 28.49
        else if $scope.current_size == 'big' && $scope.current_format == 'paysage'
          price = 46.99
        else if $scope.current_size == 'small' && $scope.current_format == 'paysage'
          price = 34.99 
        else if $scope.current_size == 'big' && $scope.current_format == 'carree'
          price = 55.99
        else if $scope.current_size == 'small' && $scope.current_format == 'carree'
          price = 34.99
        else if $scope.current_size == 'mini' && $scope.current_format == 'carree'
          price = 14.99

      
      if $scope.current_format == 'carree'
        $scope.price = price + ((parseInt($scope.nb_page) - 24) * 0.90)
      else
        $scope.price = price + ((parseInt($scope.nb_page) - 24) * 0.75)
      $scope.price_not_promo = $scope.price.toFixed(2);
      $scope.price = ($scope.price - ($scope.price * 15 / 100)).toFixed(2);
    
  $scope.set_nb_page = (nb_page) ->
    $scope.nb_page = nb_page
    $scope.set_price()
    
  $scope.less_page = () ->
     $scope.nb_page -= 2
     $scope.set_price()
  
  $scope.add_page = () ->
     $scope.nb_page += 2
     $scope.set_price()

  $scope.close_bandeau = () ->
    $scope.bandeau_app_mobile.hide()
  
  
  $scope.close_modal = () ->
    #$scope.modal_create_yearbook.hide()
    #$scope.apply_scope()
    #$('.modal-backdrop').hide()
    #$('.modal').modal('hide')
    window.history.go(-1)
    return false
    
  $scope.apply_scope = () ->
    try
      if !$scope.$$phase
        $scope.$apply()
    catch e
      #error
  
#   $(document).ready(function() {
#     $('#spanTips span').on('click', changeClass);
# });

# function changeClass() {
#    $('#spanTips span').removeClass('spanrounded');
#     $(this).addClass('active');
# }
  # $scope.changer_classe =() -> 
  #   function changeClass() {
  #               document.getElementById('spanTips').className = "spanrounded";
                
  #           }
  $('.active').addClass('spanrounded').removeClass('active');

  
  
  $scope.afficherModal = () ->
    $scope.my_modal = new bootstrap.Modal(document.getElementById('myModal'), {})
    $scope.my_modal.show()
  
  $scope.afficherModal2 = () ->
    $scope.my_modal2 = new bootstrap.Modal(document.getElementById('myModal2'), {})
    $scope.my_modal2.show()
  
  $scope.afficherModal3 = (format) ->
    $scope.my_modal3 = new bootstrap.Modal(document.getElementById('myModal3'), {})
    document.getElementById('format').innerHTML = format;
    $scope.my_modal3.show()

  $scope.afficherModal4 = () ->
    $scope.my_modal4 = new bootstrap.Modal(document.getElementById('myModal4'), {})
    $scope.my_modal4.show()

  $scope.afficherModalProduit = () ->
    $scope.my_modalProduit = new bootstrap.Modal(document.getElementById('myModalProduit'), {})
    $scope.my_modalProduit.show()

  $scope.afficherModal5 = (format) ->
    $scope.my_modal5 = new bootstrap.Modal(document.getElementById('myModal5'), {})
    document.getElementById('format1').innerHTML = format;
    $scope.my_modal5.show()

  $scope.afficherModal_theme = () ->
    $scope.my_modal_theme = new bootstrap.Modal(document.getElementById('myModal_theme'), {})
    $scope.my_modal_theme.show()

  $scope.open_rappel_app = () ->
    $scope.open_rappel_app = new bootstrap.Modal(document.getElementById('rappel_app_albums'), {})
    $scope.open_rappel_app.show()
  

  $scope.open_app_download = () ->
    $scope.open_app_download = new bootstrap.Modal(document.getElementById('editor_dowload_app'), {})
    if ($(window).width() < 500)
      $scope.open_app_download.show()

  $scope.afficherModal_theme2 = () ->
    $scope.myModal_theme2 = new bootstrap.Modal(document.getElementById('myModal_theme2'), {})
    $scope.myModal_theme2.show()
  $scope.next_product_carousel = (product) ->
    if product == "souple"
      if $scope.current_product_souple1 == 3
        $scope.current_product_souple1 = 1
      else
        $scope.current_product_souple1 = $scope.current_product_souple1 + 1
    else if product == "rigide"
      if $scope.current_product_rigide1 == 3
        $scope.current_product_rigide1 = 1
      else
        $scope.current_product_rigide1 = $scope.current_product_rigide1 + 1

  $scope.previous_product_carousel = (product) ->
    if product == "souple"
      if $scope.current_product_souple1 == 1
        $scope.current_product_souple1 = 3
      else
        $scope.current_product_souple1 = $scope.current_product_souple1 - 1
    else if product == "rigide"
      if $scope.current_product_rigide1 == 1
        $scope.current_product_rigide1 = 3
      else
        $scope.current_product_rigide1 = $scope.current_product_rigide1 - 1
    $scope.apply_scope()
    
  $scope.apply_scope = () ->
    try
      if !$scope.$$phase
        $scope.$apply()
    catch e
  
  # $scope.gotTo = (product) ->
  #    $scope.current_product_souple = product
  # $scope.gotToRigide = (product) ->
  #   $scope.current_product_rigide = product
  $scope.gotToModal = (product) ->
     $scope.current_product_souple1 = product
  $scope.gotToRigideModal = (product) ->
    $scope.current_product_rigide1 = product
  
  $scope.open_change_format = (yearbook) ->
    console.log(yearbook)
    $scope.method_type = "update"
    $scope.current_social_network_id = yearbook.social_network_id
    $scope.nb_page = yearbook.page
    $scope.current_product = yearbook.type_cover
    $scope.current_template = yearbook.theme
    
    $scope.set_price()
    
    if yearbook.format == "a4"
      $scope.current_size = "big"
      $scope.current_format = "portrait"
    else if yearbook.format == "a4_paysage"
      $scope.current_size = "big"
      $scope.current_format = "paysage"
    else if yearbook.format == "a5"
      $scope.current_size = "small"
      $scope.current_format = "portrait"
    else if yearbook.format == "a5_paysage"
      $scope.current_size = "small"
      $scope.current_format = "paysage"
    else if yearbook.format == "carre_21"
      $scope.current_size = "small"
      $scope.current_format = "carree"
    else if yearbook.format == "carre_30"
      $scope.current_size = "big"
      $scope.current_format = "carree"


    new bootstrap.Modal(document.getElementById('create_yearbook_modal'), {}).show()
    $scope.apply_scope()
    
    #$scope.change_format_yearbook_id = yearbook_id
    #$scope.modal_change_format = new bootstrap.Modal(document.getElementById('modal_change_format'), {})
    #$scope.modal_change_format.show()
  
  $scope.change_yearbook_format = () ->
    $(".info_yearbook_format_" + $scope.change_format_yearbook_id).hide()
    $.post("/social_networks/0/yearbooks/change_yearbook_format", {yearbook_id: $scope.change_format_yearbook_id})
      .then (response) ->
        $scope.modal_change_format.hide()
        $scope.open_quick_create_yearbook(response.social_network_id, response.picture_size)

        
    
  $scope.open_quick_create_yearbook = (social_network_id, picture_size) ->
    $scope.method_type = "create"
    $scope.current_social_network_id = social_network_id
    if picture_size > 63
      $scope.nb_page = 64
    else if picture_size > 51
      $scope.nb_page = 52
    else if picture_size > 47
      $scope.nb_page = 48
    else if picture_size > 39
      $scope.nb_page = 40
    else if picture_size > 31
      $scope.nb_page = 32
    
    $scope.set_price()
    
    $scope.current_product = 'album'
    $scope.current_template = 'occasion'
    new bootstrap.Modal(document.getElementById('create_yearbook_modal'), {}).show()
    $scope.apply_scope()
]
